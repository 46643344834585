/*Custom Font*/
@font-face {
  font-family: Avenir;
  src: url({{root}}assets/font/AvenirNextLTPro-Cn.woff) format("woff");
}

.top-bar-left {
  margin-top: 20px;

}

.submenu a {
  font-size: 0.9rem;
}

.language {
  text-align: right;
  a {
    font-size: 0.8rem;
  }
}

.special_navi {
  margin-top: 24px;
}

img.logo {
  width: 40%;
  @include breakpoint(medium) {
    width: 75%;
  }
  @include breakpoint(large) {
    width: 70%;
  }

}

.main_content {
  margin-top: 10%;
}

.home_content {
  margin-top: 10%;
}


.member {
  margin-top: 3rem;
  @include breakpoint(medium) {
    margin-top: 0;
  }

  h1 {
    line-height: 13px;
    padding-top: 20px;
  }
}

.about_cnt {
  margin-top: 3rem;
  @include breakpoint(medium) {
    margin-top: 0;
  }
}

.contact_image {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
#footer {
  margin-top: 100px;
  a {
    font-size: 0.8rem;
    color: white;
  }
}
